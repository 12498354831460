import React, {Component} from 'react';

import $ from 'jquery';
import Cookies from 'js-cookie';

import LayoutLPOne from '../components/layoutLPOne';
import SEO from "../components/seo";

import FormField from '../components/utilities/FormField';

import './automotivemarketing.scss';

class AutomotiveForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        };

        this.submit = this.submit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
    }

    submit(e) {
        e.preventDefault();
        let formObject = $('#heroForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();

        // console.log(post_data);

        this.setState({
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        });

        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                // let r = data[0];
                // let redirect_url = "https://www.meritmile.com/uploads/2019/01/Merit-Mile-Industry-Pulse-2019-Outdoor-Retail-Industry-Trends.pdf";

                // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                // window.location.href = redirect_url;

                $('#heroForm').hide();
                $('.form-wrapper .thankyou').show();
                // window.open(
                //     'https://mm-stats.com/gcd84',
                //     '_blank' // <- This is what makes it open in a new tab.
                // );
            },
            // Fail..
            error: function(result) {
                let r = result[0];

                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }

    nameChange(e){
        this.setState({contactName: e.target.value})
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }

    companyChange(e){
        this.setState({contactCompany: e.target.value})
    }

    render() {
        return (
            <form id="heroForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                <FormField fieldID={"name"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Full Name"}/>
                <FormField fieldID={"email"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                <FormField fieldID={"company"}  valChange={this.companyChange} val={this.state.contactCompany} type={"text"}  name={"company"} label={"Company"}/>
                <FormField fieldID={"telephone"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"telephone"} label={"Telephone"}/>
                <button type="submit" className="btn btn-white">Contact Us Now</button>
                <div className="form-group">
                    <input type="hidden" name="referrer" value="https://meritmile.com/automotive-marketing/" />
                    <input type="hidden" name="auth" value="fa84e355-176f-467d-818d-a17e40d9fd3b" />
                    <input type="text" className="d-none hu" id="human-check" name="human-check" />
                    <div className="feedback"></div>
                    <div className="fix"></div>
                </div>
            </form>
        )
    }
}

export default ({pageContext}) => (
    <LayoutLPOne>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className={`${pageContext.slug} automotivemarketing-wrapper`}>
            <div className="lp-hero">
                <div className="container margin-b">
                    <div className="row margin-b">
                        <div className="col-md-8 hero-title">
                            <h1>Automotive PR<br />&amp; Marketing<span className="bar"></span></h1>
                        </div>
                        <div className="col-md-4 hero-bg">
                            <div className="inside-hero text-center">
                                <h2 className="inside-hero-h2">Discover how Merit Mile helps leading automotive brands create awareness and generate demand for services.</h2>
                                <div className="form-wrapper">
                                    <AutomotiveForm />
                                    <div className="thankyou">
                                    <h4>Thank You<br />For your interest</h4>
                                    <p>A member of our team will be reaching out to you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div dangerouslySetInnerHTML={{__html: pageContext.content}} />
        </div>
    </LayoutLPOne>
)